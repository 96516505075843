
import React from 'react'
import { IncomesData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { rapportInputClassContainer } from '../shared/classNameStyles'
import { RootState } from '../redux/calculatorSlice'
import { useTranslation } from 'react-i18next'

const RevenusTable = () => {
  const incomes = useSelector((state: RootState) => state.incomes) || []
  const incomesArray = Object.values(incomes)
  const { t } = useTranslation()

  return (
    <table className="table-auto w-full">
      <thead className="border-b">
        <tr className="bg-main-color-44">
          <th colSpan={2} className="text-center uppercase text-white p-4 font-semibold">
          {t('incomes')}
          </th>
        </tr>
      </thead>
      <tbody>
        {IncomesData.map((incomes, index) => {
          return (
            <tr key={incomes.id} className="border-b hover:bg-gray-50">
              <td className="w-2/3 border-r-2 px-4 py-1">{t(incomes.name)}</td>
              <td
                className={
                  incomesArray[index] < 0
                    ? rapportInputClassContainer + ' text-red-500'
                    : rapportInputClassContainer
                }
              >
                {Number(incomesArray[index]).toFixed(2)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default RevenusTable
