// React et associés
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Datas
import { RevenusBrutsAnnuelData } from '../../data/CalculatorData'

const RevenusBrutsAnnuel: React.FC = () => {
  // Les valeurs stockées dans le local storage:

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let start = event.target.selectionStart
    let inputValue = event.target.value

    inputValue = inputValue.replace(/([^0-9.]+)/, '')
    inputValue = inputValue.replace(/^(0|\.)/, '')

    const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
      inputValue,
    )!
    const value: any = match[1] + match[2]

    event.target.value = Number(value).toFixed(2)
    event.target.setSelectionRange(start, start)

    const valueFixed: number = Number(Number(value).toFixed(2));
  }

  return (
    <>
      <table className="border-2 border-black d-flex mx-5">
        <thead>
          <tr>
            {RevenusBrutsAnnuelData.map((revenusBrutsTitle) => (
              <th className="border-2 border-black mx-2 text-center  align-middle">
                {revenusBrutsTitle.name}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr className="border-2 border-black text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
          <tr className="text-center">
          <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
            <td className="border-2 border-black"><input className='text-center' type="text" /></td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default RevenusBrutsAnnuel
