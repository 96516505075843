export const formatString = (value: any): string => {
  if (value !== undefined) {
    return Number(value).toFixed(2)
  } else {
    return '0.00'
  }
}

export const formatNumber = (value: any): number => {
  if (value !== undefined) {
    return Number(value)
  } else {
    return 0
  }
}
