// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAp0GGjGcI9CmpUumFG8MB1R_JJhqmAm20",
  authDomain: "gestion-immo-coachingjd-c3714.firebaseapp.com",
  projectId: "gestion-immo-coachingjd-c3714",
  storageBucket: "gestion-immo-coachingjd-c3714.appspot.com",
  messagingSenderId: "499854906511",
  appId: "1:499854906511:web:ea470e7e247fb3dfa44cfa",
  measurementId: "G-4MVWJB6EJZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const database = getFirestore(app)

export {
  app,
  analytics,
  auth,
  sendPasswordResetEmail,
  database,
}
