import React from "react"

const ConnectionButton: React.FC<{ title: string, disabled: boolean }> = (props) => {
  return (
    <button
      className="group relative w-10/12 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-main-color-44 hover:bg-main-color-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-color-70"
      type="submit"
    >
      {props.title}
    </button>
  )
}

export default ConnectionButton
