import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BuildingInfoData } from '../data/CalculatorData'
import { formatString } from '../functions/HelperFunctions'
import { buildingInfoReducer, RootState } from '../redux/calculatorSlice'
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'

const BuildingInfoContainer: React.FC = () => {
  const buildingInfoObject = JSON.parse(
    localStorage.getItem('buildingInfo') || '{}',
  )
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [buildingInfo, setBuildingInfo] = useState({
    nomCourtier: buildingInfoObject.nomCourtier || '',
    address: buildingInfoObject.address || '',
    nbUnite: buildingInfoObject.nbUnite || 1,
    prixDemande: buildingInfoObject.prixDemande || 0,
    plusValue: formatString(buildingInfoObject.plusValue),
    tauxMDF: buildingInfoObject.tauxMDF || 0,
    miseDeFond: buildingInfoObject.miseDeFond || 0,
  })

  const prixOffert =
    useSelector((state: RootState) => state.yields.prixOffert) || 0
  const montantbpv =
    useSelector((state: RootState) => state.creditors.montantBPV) || 0

  useEffect(() => {
    const newPlusValue = Number(prixOffert) * 0.025
    const newMiseDeFond =
      (Number(prixOffert) * buildingInfo.tauxMDF) / 100 - Number(montantbpv)

    setBuildingInfo((prev) => ({
      ...prev,
      plusValue: newPlusValue.toFixed(2),
      miseDeFond: newMiseDeFond.toFixed(2),
    }))

    dispatch(buildingInfoReducer({ id: 'buildingInfo', buildingInfo }))
    localStorage.setItem('buildingInfo', JSON.stringify(buildingInfo))
  }, [...Object.values(buildingInfo), prixOffert, montantbpv, dispatch])

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let inputValue = event.target.value

    if (id !== 'price') {
      switch (id) {
        case 'broker':
          setBuildingInfo({ ...buildingInfo, nomCourtier: inputValue })
          break
        case 'address':
          setBuildingInfo({ ...buildingInfo, address: inputValue })
          break
        case 'unit':
          setBuildingInfo({
            ...buildingInfo,
            nbUnite: event.target.valueAsNumber,
          })
          break
        case 'downpaymentrate':
          const newTauxMDF = event.target.valueAsNumber
          const newMiseDeFond =
            (Number(prixOffert) * newTauxMDF) / 100 - Number(montantbpv)
          setBuildingInfo({
            ...buildingInfo,
            tauxMDF: newTauxMDF,
            miseDeFond: newMiseDeFond.toFixed(2),
          })
          break
        default:
          break
      }
    } else {
      let start = event.target.selectionStart
      let inputValue = event.target.value

      inputValue = inputValue.replace(/([^0-9.]+)/, '')
      inputValue = inputValue.replace(/^(0|\.)/, '')

      const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
        inputValue,
      )!
      const value = match[1] + match[2]

      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)

      switch (id) {
        case 'price':
          setBuildingInfo({ ...buildingInfo, prixDemande: value })
          break
        default:
          break
      }
    }
  }

  return (
    <>
      <div key={BuildingInfoData[0].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[0].id}>
          {t(BuildingInfoData[0].name)}
        </label>
        <input
          id={BuildingInfoData[0].id}
          name={BuildingInfoData[0].id}
          type={BuildingInfoData[0].type}
          value={buildingInfo.nomCourtier}
          className={inputClassContainer}
          onChange={HandleInput(BuildingInfoData[0].id)}
        />
      </div>
      <div key={BuildingInfoData[1].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[1].id}>
          {t(BuildingInfoData[1].name)}
        </label>
        <input
          id={BuildingInfoData[1].id}
          name={BuildingInfoData[1].id}
          type={BuildingInfoData[1].type}
          value={buildingInfo.address}
          className={inputClassContainer}
          onChange={HandleInput(BuildingInfoData[1].id)}
          autoComplete="true"
        />
      </div>
      <div key={BuildingInfoData[2].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[2].id}>
          {t(BuildingInfoData[2].name)}
        </label>
        <input
          id={BuildingInfoData[2].id}
          name={BuildingInfoData[2].id}
          type={BuildingInfoData[2].type}
          min={1}
          value={buildingInfo.nbUnite}
          className={inputClassContainer}
          onChange={HandleInput(BuildingInfoData[2].id)}
        />
      </div>
      <div key={BuildingInfoData[3].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[3].id}>
          {t(BuildingInfoData[3].name)}
        </label>
        <input
          id={BuildingInfoData[3].id}
          name={BuildingInfoData[3].id}
          type={BuildingInfoData[3].type}
          step={5}
          min={1}
          value={buildingInfo.prixDemande}
          className={inputClassContainer}
          onChange={HandleInput(BuildingInfoData[3].id)}
        />
      </div>
      <div key={BuildingInfoData[4].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[4].id}>
          {t(BuildingInfoData[4].name)}
        </label>
        <input
          id={BuildingInfoData[4].id}
          name={BuildingInfoData[4].id}
          type={BuildingInfoData[4].type}
          readOnly
          value={Number(buildingInfo.plusValue).toFixed(2)}
          disabled={true}
          className={inputClassContainer + ' bg-gray-300'}
        />
      </div>
      <div key={BuildingInfoData[5].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[5].id}>
          {t(BuildingInfoData[5].name)}
        </label>
        <input
          id={BuildingInfoData[5].id}
          name={BuildingInfoData[5].id}
          type={BuildingInfoData[5].type}
          step={5}
          min={0}
          value={buildingInfo.tauxMDF}
          className={inputClassContainer}
          onChange={HandleInput(BuildingInfoData[5].id)}
        />
      </div>
      <div key={BuildingInfoData[6].id} className={divClassContainer}>
        <label htmlFor={BuildingInfoData[6].id}>
          {t(BuildingInfoData[6].name)}
        </label>
        <input
          id={BuildingInfoData[6].id}
          name={BuildingInfoData[6].id}
          type={BuildingInfoData[6].type}
          className={inputClassContainer + ' bg-gray-300'}
          value={Number(buildingInfo.miseDeFond).toFixed(2)}
          readOnly
        />
      </div>
    </>
  )
}

export default BuildingInfoContainer
