import { Route, Routes, Navigate } from 'react-router-dom'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
// import { useContext } from 'react'

import { RootState } from './redux/calculatorSlice'

import CalculateurAccueil from './pages/Calculator/CalculateurAccueil'
import CalculateurLocatif from './pages/Calculator/CalculateurLocatif'
import CalculateurDev from './pages/Calculator/CalculateurDev'
import ForgetPassword from './pages/ForgetPassword'
import NotFound from './pages/NotFound'
// import Header from './components/Header'
// import Tenant from './pages/Tenant'
// import Tenancy from './pages/Tenancy'
import Signup from './pages/Signup'
import Login from './pages/Login'
import Print from './components/Print'
import React from 'react'

function App() {
  // console.log(localStorage.getItem('MY_TOKEN'))
  // ici, on vérifie si notre token existe encore
  const userIsLoggedIn = useSelector((state: RootState) => state.isloggedIn)

  return (
    <>
      <Fragment>
        <Routes>
          <Route path="/" element={<Navigate replace to="/signin" />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/print" element={<Print />} />
          {userIsLoggedIn && (
            <Route path="/calculateur" element={<CalculateurAccueil />} />
          )}
          {userIsLoggedIn && (
            <Route
              path="/calculateur/locatif"
              element={<CalculateurLocatif />}
            />
          )}
          {userIsLoggedIn && (
            <Route path="/calculateur/locatif/print" element={<Print />} />
          )}
          {userIsLoggedIn && (
            <Route path="/calculateur/dev" element={<CalculateurDev />} />
          )}

          {userIsLoggedIn && <Route path="*" element={<NotFound />} />}
          {!userIsLoggedIn && (
            <Route path="*" element={<Navigate to="/signin" />} />
          )}
        </Routes>
      </Fragment>
    </>
  )
}

export default App
