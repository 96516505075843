import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IncomesData } from '../data/CalculatorData'
import { formatString } from '../functions/HelperFunctions'
import { revenusReducer, RootState } from '../redux/calculatorSlice'
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'

const IncomeContainer = () => {
  const revenusObject = JSON.parse(localStorage.getItem('incomes') || '{}')
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [revenus, setRevenus] = useState({
    loyersBruts: formatString(revenusObject.loyersBruts),
    loyersVacants: formatString(revenusObject.loyersVacants),
    loyersNets: formatString(revenusObject.loyersNets),
    revenuStationnement: formatString(revenusObject.revenuStationnement),
    revenuEntretienMenager: formatString(revenusObject.revenuEntretienMenager),
    autresRevenus: formatString(revenusObject.autresRevenus),
    revenusBruts: Number(revenusObject.revenusBruts || 0).toFixed(2),
    revenusNets: Number(revenusObject.revenusNets || 0).toFixed(2),
  })

  const totalExpenses =
    useSelector((state: RootState) => state.totalExpenses) || 0

  const tauxVacance =
    useSelector((state: RootState) => state.statistics.tauxVacance) || 0

  useEffect(() => {
    const {
      loyersBruts,
      revenuStationnement,
      revenuEntretienMenager,
      autresRevenus,
    } = revenus
    const newLoyersVacants = (Number(loyersBruts) * Number(tauxVacance)) / 100
    const newLoyersNets = Number(loyersBruts) - newLoyersVacants
    const newRevenusBruts =
      newLoyersNets +
      Number(revenuStationnement) +
      Number(revenuEntretienMenager) +
      Number(autresRevenus)

    const newRevenusNets = newRevenusBruts - totalExpenses

    const updatedIncomes = {
      ...revenus,
      loyersVacants: newLoyersVacants.toFixed(2),
      loyersNets: newLoyersNets.toFixed(2),
      revenusBruts: newRevenusBruts.toFixed(2),
      revenusNets: newRevenusNets.toFixed(2),
    }

    setRevenus(updatedIncomes)

    dispatch(revenusReducer({ id: 'revenus', incomes: revenus }))
    localStorage.setItem('incomes', JSON.stringify(revenus))
  }, [...Object.values(revenus), totalExpenses, tauxVacance, dispatch])

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let inputValue = event.target.value

    if (id !== 'grossrent') {
      let start = event.target.selectionStart
      inputValue = inputValue.replace(/([^0-9.]+)/, '')
      inputValue = inputValue.replace(/^(0|\.)/, '')
      const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
        inputValue,
      )!
      const value = match[1] + match[2]
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)

      switch (id) {
        case 'parking':
          setRevenus({ ...revenus, revenuStationnement: value })
          break
        case 'cleaning':
          setRevenus({ ...revenus, revenuEntretienMenager: value })
          break
        case 'otherincome':
          setRevenus({ ...revenus, autresRevenus: value })
          break
        default:
          break
      }
    } else {
      let start = event.target.selectionStart
      let inputValue = event.target.value
      inputValue = inputValue.replace(/([^0-9.]+)/, '')
      inputValue = inputValue.replace(/^(0|\.)/, '')
      const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
        inputValue,
      )!
      const value = match[1] + match[2]
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)

      switch (id) {
        case 'grossrent':
          setRevenus({ ...revenus, loyersBruts: value })
          break
        default:
          break
      }
    }
  }

  return (
    <>
      <div key={IncomesData[0].id} className={divClassContainer}>
        <label htmlFor={IncomesData[0].id}>{t(IncomesData[0].name)}</label>
        <input
          id={IncomesData[0].id}
          name={IncomesData[0].id}
          type={IncomesData[0].type}
          className={inputClassContainer}
          value={revenus.loyersBruts}
          onChange={HandleInput(IncomesData[0].id)}
        />
      </div>
      <div key={IncomesData[1].id} className={divClassContainer}>
        <label htmlFor={IncomesData[1].id}>{t(IncomesData[1].name)}</label>
        <input
          id={IncomesData[1].id}
          name={IncomesData[1].id}
          type={IncomesData[1].type}
          value={Number(revenus.loyersVacants).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={IncomesData[2].id} className={divClassContainer}>
        <label htmlFor={IncomesData[2].id}>{t(IncomesData[2].name)}</label>
        <input
          id={IncomesData[2].id}
          name={IncomesData[2].id}
          type={IncomesData[2].type}
          value={Number(revenus.loyersNets).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        ></input>
      </div>
      <div key={IncomesData[3].id} className={divClassContainer}>
        <label htmlFor={IncomesData[3].id}>{t(IncomesData[3].name)}</label>
        <input
          id={IncomesData[3].id}
          name={IncomesData[3].id}
          type={IncomesData[3].type}
          value={revenus.revenuStationnement}
          className={inputClassContainer}
          onChange={HandleInput(IncomesData[3].id)}
        />
      </div>
      <div key={IncomesData[4].id} className={divClassContainer}>
        <label htmlFor={IncomesData[4].id}>{t(IncomesData[4].name)}</label>
        <input
          id={IncomesData[4].id}
          name={IncomesData[4].id}
          type={IncomesData[4].type}
          value={revenus.revenuEntretienMenager}
          className={inputClassContainer}
          onChange={HandleInput(IncomesData[4].id)}
        />
      </div>
      <div key={IncomesData[5].id} className={divClassContainer}>
        <label htmlFor={IncomesData[5].id}>{t(IncomesData[5].name)}</label>
        <input
          id={IncomesData[5].id}
          name={IncomesData[5].id}
          type={IncomesData[5].type}
          value={revenus.autresRevenus}
          className={inputClassContainer}
          onChange={HandleInput(IncomesData[5].id)}
        />
      </div>
      <div key={IncomesData[6].id} className={divClassContainer}>
        <label htmlFor={IncomesData[6].id}>{t(IncomesData[6].name)}</label>
        <input
          id={IncomesData[6].id}
          name={IncomesData[6].id}
          type={IncomesData[6].type}
          value={Number(revenus.revenusBruts).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={IncomesData[7].id} className={divClassContainer}>
        <label htmlFor={IncomesData[7].id}>{t(IncomesData[7].name)}</label>
        <input
          id={IncomesData[7].id}
          name={IncomesData[7].id}
          type={IncomesData[7].type}
          value={Number(revenus.revenusNets).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
    </>
  )
}

export default IncomeContainer
