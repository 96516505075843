import React, { useState, useEffect } from 'react'

import { StatistiqueMarcheData } from '../data/CalculatorData'
import { useDispatch } from 'react-redux'
import { statDuMarcheReducer } from '../redux/calculatorSlice'

// styles partagés
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'
import { useTranslation } from 'react-i18next'
import { formatNumber, formatString } from '../functions/HelperFunctions'

const Statistiques = () => {
  // Les valeurs stockées dans le local storage:
  const statisticsObject = JSON.parse(
    localStorage.getItem('statistics') || '{}',
  )

  const initialStatistics = {
    tauxAdmin: formatString(statisticsObject.tauxAdmin),
    mrbSecteur: formatString(statisticsObject.mrbSecteur),
    mrnSecteur: formatString(statisticsObject.mrnSecteur),
    tgaSecteur: formatNumber(statisticsObject.tgaSecteur),
    tauxVacance: formatString(statisticsObject.tauxVacance),
    tauxCreanceDivers: formatString(statisticsObject.tauxCreanceDivers),
    tauxReserve: formatString(statisticsObject.tauxReserve),
    deneigement: formatString(statisticsObject.deneigement),
    pelouse: formatString(statisticsObject.pelouse),
    depensesCourantes: formatString(statisticsObject.depensesCourantes),
  }

  const [statistics, setStatistics] = useState(initialStatistics)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let start = event.target.selectionStart
    let inputValue = event.target.value

    inputValue = inputValue.replace(/([^0-9.]+)/, '')
    inputValue = inputValue.replace(/^(0|\.)/, '')

    const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
      inputValue,
    )!
    const value: any = match[1] + match[2]

    event.target.value = Number(value).toFixed(2)
    event.target.setSelectionRange(start, start)

    const valueFixed: string = value.toString()

    switch (id) {
      case 'tauxFraisadmin':
        
        setStatistics({ ...statistics, tauxAdmin: valueFixed })
        break
      case 'mrb':
        setStatistics({ ...statistics, mrbSecteur: valueFixed })
        break
      case 'mrn':
        if (Number(valueFixed) !== 0) {
          setStatistics({
            ...statistics,
            mrnSecteur: valueFixed,
            tgaSecteur: 100 / Number(valueFixed),
          })
        } else {
          setStatistics({
            ...statistics,
            tgaSecteur: 0,
          })
        }

        break
      case 'tauxvacance':
        setStatistics({ ...statistics, tauxVacance: valueFixed })
        break
      case 'tauxcreancedivers':
        setStatistics({ ...statistics, tauxCreanceDivers: valueFixed })
        break
      case 'tauxreserve':
        setStatistics({ ...statistics, tauxReserve: valueFixed })
        break
      case 'snowremoval':
        setStatistics({ ...statistics, deneigement: valueFixed })
        break
      case 'grassremoval':
        setStatistics({ ...statistics, pelouse: valueFixed })
        break
      case 'individualexpensedoor':
        setStatistics({ ...statistics, depensesCourantes: valueFixed })
        break
      default:
        break
    }
  }

  useEffect(() => {
    localStorage.setItem('statistics', JSON.stringify(statistics))
    dispatch(statDuMarcheReducer({ id: 'statistics', statistics }))
  }, [...Object.values(statistics)])

  return (
    <>
      <div key={StatistiqueMarcheData[0].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[0].id}>
          {t(StatistiqueMarcheData[0].name)}
        </label>
        <input
          id={StatistiqueMarcheData[0].id}
          name={StatistiqueMarcheData[0].id}
          type={StatistiqueMarcheData[0].type}
          className={inputClassContainer}
          value={statistics.tauxAdmin}
          onChange={HandleInput(StatistiqueMarcheData[0].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[1].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[1].id}>
          {t(StatistiqueMarcheData[1].name)}
        </label>
        <input
          id={StatistiqueMarcheData[1].id}
          name={StatistiqueMarcheData[1].id}
          type={StatistiqueMarcheData[1].type}
          className={inputClassContainer}
          value={statistics.mrbSecteur}
          onChange={HandleInput(StatistiqueMarcheData[1].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[2].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[2].id}>
          {t(StatistiqueMarcheData[2].name)}
        </label>
        <input
          id={StatistiqueMarcheData[2].id}
          name={StatistiqueMarcheData[2].id}
          type={StatistiqueMarcheData[2].type}
          className={inputClassContainer}
          value={statistics.mrnSecteur}
          onChange={HandleInput(StatistiqueMarcheData[2].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[3].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[3].id}>
          {t(StatistiqueMarcheData[3].name)}
        </label>
        <input
          id={StatistiqueMarcheData[3].id}
          name={StatistiqueMarcheData[3].id}
          type={StatistiqueMarcheData[3].type}
          step={0.01}
          className={inputClassContainer + ' bg-gray-300'}
          value={Number(statistics.tgaSecteur).toFixed(2)}
          onChange={HandleInput(StatistiqueMarcheData[3].id)}
          readOnly
        />
      </div>
      <div key={StatistiqueMarcheData[4].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[4].id}>
          {t(StatistiqueMarcheData[4].name)}
        </label>
        <input
          id={StatistiqueMarcheData[4].id}
          name={StatistiqueMarcheData[4].id}
          type={StatistiqueMarcheData[4].type}
          className={inputClassContainer}
          value={statistics.tauxVacance}
          onChange={HandleInput(StatistiqueMarcheData[4].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[5].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[5].id}>
          {t(StatistiqueMarcheData[5].name)}
        </label>
        <input
          id={StatistiqueMarcheData[5].id}
          name={StatistiqueMarcheData[5].id}
          type={StatistiqueMarcheData[5].type}
          className={inputClassContainer}
          value={statistics.tauxCreanceDivers}
          onChange={HandleInput(StatistiqueMarcheData[5].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[6].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[6].id}>
          {t(StatistiqueMarcheData[6].name)}
        </label>
        <input
          id={StatistiqueMarcheData[6].id}
          name={StatistiqueMarcheData[6].id}
          type={StatistiqueMarcheData[6].type}
          className={inputClassContainer}
          value={statistics.tauxReserve}
          onChange={HandleInput(StatistiqueMarcheData[6].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[7].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[7].id}>
          {t(StatistiqueMarcheData[7].name)}
        </label>
        <input
          id={StatistiqueMarcheData[7].id}
          name={StatistiqueMarcheData[7].id}
          type={StatistiqueMarcheData[7].type}
          className={inputClassContainer}
          value={statistics.deneigement}
          onChange={HandleInput(StatistiqueMarcheData[7].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[8].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[8].id}>
          {t(StatistiqueMarcheData[8].name)}
        </label>
        <input
          id={StatistiqueMarcheData[8].id}
          name={StatistiqueMarcheData[8].id}
          type={StatistiqueMarcheData[8].type}
          className={inputClassContainer}
          value={statistics.pelouse}
          onChange={HandleInput(StatistiqueMarcheData[8].id)}
        />
      </div>
      <div key={StatistiqueMarcheData[9].id} className={divClassContainer}>
        <label htmlFor={StatistiqueMarcheData[9].id}>
          {t(StatistiqueMarcheData[9].name)}
        </label>
        <input
          id={StatistiqueMarcheData[9].id}
          name={StatistiqueMarcheData[9].id}
          type={StatistiqueMarcheData[9].type}
          className={inputClassContainer}
          value={statistics.depensesCourantes}
          onChange={HandleInput(StatistiqueMarcheData[9].id)}
        />
      </div>
    </>
  )
}

export default Statistiques
