import { getAuth, User } from 'firebase/auth'
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDocs,
  query,
  QuerySnapshot,
  updateDoc,
  where,
} from 'firebase/firestore'
import { database } from '../redux/firebase'

const usersCollectionRef = collection(database, 'etudiants')
// const dispatch = useDispatch()

const getUserData = async (email: string) => {
  const q = query(
    collection(database, 'etudiants'),
    where('email', '==', email),
  )
  const userData = await getDocs(q).then(async (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docs.map(doc => doc.data())
    return data[0]
  })
  
  return userData
}

const getUserDocId = async (email: string) => {
  const q = query(
    collection(database, 'etudiants'),
    where('email', '==', email),
  )
  const userData = await getDocs(q).then(async (snapshot: QuerySnapshot<DocumentData>) => {
    const id = snapshot.docs.map(doc => doc.id)
    return id[0]
  })
  return userData
}

const getUsersEmails = async () => {
  const docs: any = await getDocs(usersCollectionRef)
    .then(async (snapshot: QuerySnapshot<DocumentData>) => {
      const data = snapshot.docs.map((doc) => doc.data())
      const emails = data.map((etudiant) => etudiant.email)
      return emails
    })
    .catch((error: any) => {
      console.error('Impossible de lire les données: ', error)
    })
  return docs
}

const createDocs = async (values: any[]) => {
  await addDoc(usersCollectionRef, { ...values })
}

const updateUserStatDuMarche = async (id: string, statistics: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { statistics }
  await updateDoc(userDoc, newFields)
}

const updateUserBuildingInfo = async (id: string, buildingInfo: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { buildingInfo }

  await updateDoc(userDoc, newFields)
}

const updateUserIncomes = async (id: string, incomes: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { incomes }

  await updateDoc(userDoc, newFields)
}

const updateUserExpenses = async (id: string, expenses: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { expenses }

  await updateDoc(userDoc, newFields)
}

const updateUserCreditors = async (id: string, creditors: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { creditors }

  await updateDoc(userDoc, newFields)
}

const updateUserReturn = async (id: string, yields: Object) => {
  const userDoc = doc(database, 'etudiants', id)
  const newFields = { yields }

  await updateDoc(userDoc, newFields)
}

export {
  getUserDocId,
  getUserData,
  getUsersEmails,
  createDocs,
  updateUserIncomes,
  updateUserExpenses,
  updateUserBuildingInfo,
  updateUserCreditors,
  updateUserStatDuMarche,
  updateUserReturn,
}


