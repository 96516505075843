import React from 'react'
import { StatistiqueMarcheData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/calculatorSlice'
import { useTranslation } from 'react-i18next'

const StatistiquesTable = () => {
  const statistics = useSelector((state: RootState) => state.statistics) || {}
  const statisticsArray = Object.values(statistics) || []
  const { t } = useTranslation()

  return (
    <div>
      <table className="table-auto w-full">
        <thead className="border-b">
          <tr className="bg-main-color-44">
            <th colSpan={2} className="text-white p-4 font-semibold uppercase">
              {t('statistics')}
            </th>
          </tr>
        </thead>
        <tbody>
          {StatistiqueMarcheData.map((stat, index) => {
            return (
              <tr key={stat.id} className="border-b hover:bg-gray-50">
                <td className="w-2/3 border-r-2 px-4 py-1"> {t(stat.name)}</td>
                <td className="border-r-2 text-right px-4 py-1">
                  {Number(statisticsArray[index]).toFixed(2)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default StatistiquesTable
