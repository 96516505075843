import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState('en')

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  const toggleLanguage = () => {
    currentLanguage === 'en'
      ? setCurrentLanguage('fr')
      : setCurrentLanguage('en')
  }

  return (
    <div>
      <button
        onClick={() => {
          changeLanguage(currentLanguage); toggleLanguage()
        }}
        className='text-white'
      >
        {currentLanguage === 'en' ? 'EN' : 'FR'}
      </button>
    </div>
  )
}

export default LanguageSwitcher
