import React from 'react';
import { Fragment } from 'react'
import Header from '../components/Header';

const NotFound = () => {
  return (
    <Fragment>
      <Header/>
      <div className="flex  items-center relative h-screen">
        <h1 className="text-2xl m-auto">
          Nous travaillons pour vous: la page est introuvable
        </h1>
      </div>
    </Fragment>
  )
}

export default NotFound
