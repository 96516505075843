import { useTranslation } from 'react-i18next'

interface data {
  id: string
  type: string
  name: string
}

export const StatistiqueMarcheData: data[] = [
  { id: 'tauxFraisadmin', type: 'text', name: 'adminRate' },
  { id: 'mrb', type: 'text', name: 'gim' },
  { id: 'mrn', type: 'text', name: 'nim' },
  { id: 'tga', type: 'text', name: 'capRate' },
  { id: 'tauxvacance', type: 'text', name: 'vacancyRates' },
  {
    id: 'tauxcreancedivers',
    type: 'text',
    name: 'variousDebtRates',
  },
  { id: 'tauxreserve', type: 'text', name: 'reserveRate' },
  {
    id: 'snowremoval',
    type: 'text',
    name: 'snowRemoval',
  },
  {
    id: 'grassremoval',
    type: 'text',
    name: 'grassRemoval',
  },
  {
    id: 'individualexpensedoor',
    type: 'text',
    name: 'individualExpenseDoor',
  },
]

export const BuildingInfoData: data[] = [
  { id: 'broker', type: 'text', name: 'broker' },
  { id: 'address', type: 'address', name: 'address' },
  { id: 'unit', type: 'number', name: 'unit' },
  { id: 'price', type: 'text', name: 'price' },
  { id: 'capitalGain', type: 'text', name: 'capitalGain' },
  { id: 'downpaymentrate', type: 'number', name: 'downPaymentRate' },
  { id: 'downpayment', type: 'text', name: 'downPayment' },
]

export const IncomesData: data[] = [
  { id: 'grossrent', type: 'text', name: 'grossRent' },
  { id: 'innocupation', type: 'text', name: 'vacancy' },
  { id: 'netrent', type: 'text', name: 'netRent' },
  { id: 'parking', type: 'text', name: 'parking' },
  { id: 'cleaning', type: 'text', name: 'cleaning' },
  { id: 'otherincome', type: 'text', name: 'otherIncome' },
  { id: 'grossincome', type: 'text', name: 'grossIncome' },
  { id: 'netincome', type: 'text', name: 'netIncome' },
]

export const ExpensesData: data[] = [
  { id: 'insurance', type: 'text', name: 'insurance' },
  {
    id: 'snowremovals',
    type: 'text',
    name: 'snowRemovals',
  },
  { id: 'grassremovals', type: 'text', name: 'grassremovals' },
  { id: 'electricity', type: 'text', name: 'electricity' },
  {
    id: 'combustible',
    type: 'text',
    name: 'combustible',
  },
  { id: 'municipaltaxe', type: 'text', name: 'municipaltaxe' },
  { id: 'schooltaxe', type: 'text', name: 'schoolTaxe' },
  {
    id: 'totalexpensedoor',
    type: 'text',
    name: 'totalExpenseDoor',
  },
  { id: 'reserve', type: 'text', name: 'reserve' },
  {
    id: 'FraisAdminAnnuel',
    type: 'text',
    name: 'annualAdminFees',
  },
  { id: 'creancedivers', type: 'text', name: 'variousDebt' },
  { id: 'autresDepenses', type: 'text', name: 'otherExpenses' },
  {
    id: 'depenseExploitation',
    type: 'text',
    name: 'operatingExpenses',
  },
]

export const CreditorsData: data[] = [
  { id: 'MontantPret', type: 'text', name: 'loanAmount' },
  { id: 'interetannuel', type: 'text', name: 'annualInterest' },
  { id: 'duree', type: 'number', name: 'period' },
  {
    id: 'hypotheque',
    type: 'text',
    name: 'monthlyMortgage',
  },
  {
    id: 'hypothequeannuel',
    type: 'text',
    name: 'annualMortgage',
  },
  { id: 'montantbpv', type: 'text', name: 'spbAmount' },
  { id: 'interetbpv', type: 'text', name: 'spbInterestRate' },
  { id: 'termebpv', type: 'number', name: 'periodSPB' },
  {
    id: 'remboursementbpv',
    type: 'text',
    name: 'reimbursementSPB',
  },
  {
    id: 'remboursementencapital',
    type: 'text',
    name: 'capitalRepayment',
  },
]

export const EffectiveReturnsData: data[] = [
  { id: 'prixoffert', type: 'text', name: 'offeredPrice' },
  { id: 'rendement', type: 'text', name: 'yield' },
  { id: 'mdf', type: 'text', name: 'returnDownPayment' },
  {
    id: 'tauxrecouv',
    type: 'text',
    name: 'debtRecoveryRate',
  },
  { id: 'servicedette', type: 'text', name: 'debtService' },
  { id: 'ratio', type: 'text', name: 'debtRatio' },
  {
    id: 'capitalemprunte',
    type: 'text',
    name: 'principalBorrow',
  },
  {
    id: 'cashflowmensuel',
    type: 'text',
    name: 'monthlyCashFlow',
  },
  { id: 'cashflowannuel', type: 'text', name: 'annualCashFlow' },
  { id: 'mrbeffectif', type: 'text', name: 'effectiveGIM' },
  { id: 'mrneffectif', type: 'text', name: 'effectiveNIM' },
  { id: 'tgaeffectif', type: 'text', name: 'effectiveCapRate' },
]

export const CaracteristiquesProjetData: data[] = [
  { id: 'empty', type: 'text', name: '' },
  { id: 'batimenths', type: 'text', name: 'Batiment HS' },
  { id: 'corridor', type: 'text', name: 'Corridor commun RDC' },
  { id: 'espacevert', type: 'text', name: 'Espace Vert' },
  { id: 'parkingrdc', type: 'text', name: 'Parking RDC' },
  { id: 'parkingss', type: 'text', name: 'Parking Sous-sol' },
  { id: 'residentiel', type: 'text', name: 'Commercial' },
]

export const RevenusBrutsAnnuelData: data[] = [
  { id: 'residentiel', type: 'text', name: 'Résidentiel' },
  { id: 'empty', type: 'text', name: '' },
  { id: 'stationnement', type: 'text', name: 'Stationnement' },
  { id: 'commercial', type: 'text', name: 'Commercial' },
  { id: 'ameneties', type: 'text', name: 'Ameneties' },
  { id: 'total', type: 'text', name: 'Total' },
]
