import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikErrors, FormikValues, useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getUsersEmails } from '../data/usersData'
import ConnectionButton from '../components/ConnectionButton'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'

interface MyFormValues {
  email: string
  password: string
  confirmPassword: string
}

const validate = (values: MyFormValues) => {
  let errors: FormikErrors<FormikValues> = {}

  if (!values.password) {
    errors.password = 'passwordRequiredError'
  }
  if (values.password.length < 6 || values.password.length > 15) {
    errors.password = 'passwordError'
  }

  if (!values.email) {
    errors.email = 'emailError'
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalidEmailError'
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'confirmPasswordError'
  }

  return errors
}

const defaultInputStyles =
  'w-96 h-8 mt-1 mb-4 items-center border-solid border-2 rounded-md px-4'

// const {firstName, lastName, email, password, confirmPassword} = formik.values

const Signup: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true)
      if (emails.includes(values.email)) {
        const promise = fetch(
          'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAp0GGjGcI9CmpUumFG8MB1R_JJhqmAm20',
          {
            method: 'POST',
            body: JSON.stringify({
              email: values.email,
              password: values.password,
              returnSecureToken: true,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        promise.then(async (res) => {
          toast.loading(t('loading'))
          if (res.ok) {
            toast.success(t('registrationCompleted'))

            setTimeout(() => {
              navigate('/signin')
              setIsLoading(false)
            }, 2000)
            return res.json()
          } else {
            return res.json().then((data) => {
              // Pas besoin du weak password de firebase car Formit ne soumettra pas
              let errorMessage = 'Signup failed'
              if (data && data.error && data.error.message) {
                if (data.error.message === 'EMAIL_EXISTS') {
                  errorMessage = t('alreadyGetAnAccountError')
                }
              }
              toast.error(errorMessage)
              setTimeout(() => {
                navigate('/signin')
              }, 2000)
              // On redirige vers signin si la personne a déjà un compte
              setIsLoading(false)
            })
          }
        })
      } else {
        toast.error(t('studentNotFound'))
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    // Get data from firestore
    getUsersEmails().then((emails: React.SetStateAction<string[]>) => {
      setEmails(emails)
    })
    // validate;
  }, [formik.values])

  return (
    <Fragment>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="relative flex flex-col flex-nowrap items-center justify-center">
        <div>
          <img
            className="mx-auto h-28 w-auto"
            src={require('../images/logo_coachingJD.png')}
            alt="logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('registerNow')}
          </h2>
        </div>
        <form
          className="flex-nowrap flex flex-col items-center rounded-md p-5 shadow-md h80 w-[30rem] pb-8 mt-8 space-y-6 z-10"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col justify-center">
            <label htmlFor="email">{t('email')}</label>
            <input
              id="email"
              name="email"
              type="email"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={
                formik.touched.email && formik.errors.email
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{t(formik.errors.email)}</div>
            ) : null}
          </div>
          <div className="flex flex-col justify-center">
            <label htmlFor="password">{t('password')}</label>
            <input
              id="password"
              title={t('password')}
              name="password"
              type="password"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={
                formik.touched.password && formik.errors.password
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500">{t(formik.errors.password)}</div>
            ) : null}
          </div>
          <div className="flex flex-col justify-center">
            <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-red-500">
                {t(formik.errors.confirmPassword)}
              </div>
            ) : null}
          </div>
          <ConnectionButton
            title={isLoading ? t('loading') : t('register')}
            disabled={formik.isSubmitting}
          />
          <span>
            {t('alrearyGetAnAccount')}
            <a href="/signin" className="text-main-color-44">
              {' '}
              {t('here')}
            </a>
          </span>
        </form>
      </div>
    </Fragment>
  )
}

export default Signup
