const interetMensuel = (i: number, t: number, p: number): number => {
  /*
      i - Interest Amount
      t - Time Period Involved in years
      p - Principal Amount
   */

  let capitalInteret = p * (1 + (i / 100) * t)
  let interet = capitalInteret - p
  let rembMensuel = 0

  if (t !== 0) {
    rembMensuel = interet / (t * 12)
  }

  return rembMensuel
}

export default interetMensuel
