import { BuildingInfoData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/calculatorSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'

const InfosImmeubleTable = () => {
  const buildingInfo = useSelector((state: RootState) => state.buildingInfo) || {}
  const buildingInfoArray = Object.values(buildingInfo) || []
  const { t } = useTranslation()

  return (
    <table className="table-auto w-full">
      <thead className="border-b">
        <tr className="bg-main-color-44">
          <th colSpan={2} className="text-white uppercase p-4 font-semibold">
          {t('buildingInfo')}
          </th>
        </tr>
      </thead>
      <tbody>
        {BuildingInfoData.map((info, index) => {
          return (
            <tr key={info.id} className="border-b hover:bg-gray-50">
              <td className="w-2/3 border-r-2 px-4 py-1">{t(info.name)}</td>
              <td className="border-r-2 text-right px-4 py-1">
                {buildingInfoArray[index]}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default InfosImmeubleTable