import { FormikErrors, FormikValues, useFormik, FormikProps } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ConnectionButton from '../components/ConnectionButton'
import { isLoginReducer, tokenReducer } from '../redux/calculatorSlice'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getUserData } from '../data/usersData'
import { useTranslation } from 'react-i18next'
import Header from '../components/Header'

interface MyFormValues {
  email: string
  password: string
}

const validate = (values: MyFormValues) => {
  let errors: FormikErrors<FormikValues> = {}

  if (!values.email) {
    errors.email = 'emailError'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalidEmailError'
  }

  return errors
}

const defaultInputStyles =
  'w-96 h-8 mt-1 mb-4 items-center border-solid border-2 rounded-md px-4'

const Login: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const initialToken = localStorage.getItem('MY_TOKEN')
  const [token, setToken] = useState<string | null>(initialToken)
  const { t } = useTranslation()

  const timeForTokenToExpired = (expirationTime: string | number | Date) => {
    const currentTIme = new Date().getTime()
    const adjExpirationTime = new Date(expirationTime).getTime()

    const remainingDuration = adjExpirationTime - currentTIme

    return remainingDuration
  }

  const loginHandler = (
    token: React.SetStateAction<string | null>,
    expirationTime: string | number | Date,
  ) => {
    setToken(token)
    dispatch(tokenReducer({ id: 'token', token }))
    dispatch(isLoginReducer({ id: 'isloggedIn', isloggedIn: !!token }))
    localStorage.setItem('MY_TOKEN', JSON.stringify(token))
    navigate('/calculateur')

    const remainingTime = timeForTokenToExpired(expirationTime)

    setTimeout(() => {
      dispatch(tokenReducer({ id: 'token', token: null }))
      dispatch(isLoginReducer({ id: 'isloggedIn', isloggedIn: true }))
      localStorage.clear()
      navigate('/signin')
    }, remainingTime)
  }

  const formik: FormikProps<MyFormValues> = useFormik<MyFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validate,
    onSubmit: (values) => {
      fetch(
        'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAp0GGjGcI9CmpUumFG8MB1R_JJhqmAm20',
        {
          method: 'POST',
          body: JSON.stringify({
            email: values.email,
            password: values.password,
            returnSecureToken: true,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then(async (res) => {
          if (res.ok) {
            toast.success(t('connectionSuccessFul'))
            setIsLoading(false)
            getUserData(values.email).then((userData) => {
              if (userData) {
                const {
                  email,
                  statistics,
                  buildingInfo,
                  incomes,
                  expenses,
                  creditors,
                  yields,
                } = userData // On obtient les données de la BD
                localStorage.setItem('email', email)
                localStorage.setItem(
                  'statistics',
                  JSON.stringify(statistics || {}),
                )
                localStorage.setItem(
                  'buildingInfo',
                  JSON.stringify(buildingInfo || {}),
                )
                localStorage.setItem('incomes', JSON.stringify(incomes || {}))
                localStorage.setItem('expenses', JSON.stringify(expenses || {}))
                localStorage.setItem(
                  'creditors',
                  JSON.stringify(creditors || {}),
                )
                localStorage.setItem('yields', JSON.stringify(yields || {}))
              }
            })
            return res.json()
          } else {
            return res.json().then((data) => {
              // Pas besoin du weak password de firebase car Formit ne soumettra pas
              // console.log(data)
              let errorMessage = 'Signin failed'
              if (data && data.error && data.error.message) {
                if (data.error.message === 'EMAIL_NOT_FOUND') {
                  errorMessage = 'emailNotFound'
                }

                if (data.error.message === 'INVALID_PASSWORD') {
                  errorMessage = 'incorrectPassword'
                }
              }
              throw new Error(t(errorMessage))
            })
          }
        })
        .then((data) => {
          const expirationTime = new Date(
            new Date().getTime() + +data.expiresIn * 1000,
          ) // ExpiresIn est en secondes
          // console.log(expirationTime)
          loginHandler(data.idToken, expirationTime)
        })
        .catch((error) => {
          alert(t(error.message))
        })
    },
  })

  useEffect(() => {
    // validate;
  }, [formik.values, validate])

  return (
    <Fragment>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="relative top-20 flex flex-col flex-nowrap items-center justify-center">
        <div>
          <img
            className="mx-auto h-28 w-auto"
            src={require('../images/logo_coachingJD.png')}
            alt="logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('connectToAccount')}
          </h2>
        </div>
        <form
          className="flex-nowrap flex flex-col items-center rounded-md p-5 shadow-md h80 w-[30rem] pb-8 mt-8 space-y-6 z-10"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col justify-center">
            <label htmlFor="email">{t('email')}</label>
            <input
              id="email"
              title="Courriel"
              name="email"
              type="email"
              autoComplete="email"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={
                formik.touched.email && formik.errors.email
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{t(formik.errors.email)}</div>
            ) : null}
          </div>
          <div className="flex flex-col justify-center">
            <label htmlFor="password">{t('password')}</label>
            <input
              id="password"
              title="Mot de passe"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={
                formik.touched.password && formik.errors.password
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500">{formik.errors.password}</div>
            ) : null}
          </div>

          <div className="flex justify-between">
            <div className="text-center">
              <div className="m-3 text-sm">
                <a
                  href="/forgetpassword"
                  className="font-medium text-main-color-44 hover:text-main-color-50"
                >
                  {t('forgetPassword') + '?'}
                </a>
              </div>
              <div className="m-3 text-sm">
                <span>
                  {t('dontHaveAccount')}{' '}
                  <a href="/signup" className="text-main-color-44">
                    {t('subscribeHere')}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <ConnectionButton
            title={isLoading ? t('loading') : t('connect')}
            disabled={formik.isSubmitting}
          />
        </form>
      </div>
    </Fragment>
  )
}

export default Login
