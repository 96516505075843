import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EffectiveReturnsData } from '../data/CalculatorData'
import { formatNumber, formatString } from '../functions/HelperFunctions'
import { effectiveReturnReducer, RootState } from '../redux/calculatorSlice'

// styles partagés
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'

const EffectiveReturnsContainer = () => {
  // Les valeurs stockées dans le local storage:
  const yieldsObject = JSON.parse(localStorage.getItem('yields') || '{}')

  const initialYields = {
    prixOffert: formatString(yieldsObject.prixOffert),
    rendement: formatNumber(yieldsObject.rendement),
    rendementMDF: formatNumber(yieldsObject.rendementMDF),
    tauxRecouv: formatNumber(yieldsObject.tauxRecouv),
    serviceDette: formatNumber(yieldsObject.serviceDette),
    ratio: formatNumber(yieldsObject.ratio),
    capitalEmprunte: formatNumber(yieldsObject.capitalEmprunte),
    cashFlowMensuel: formatNumber(yieldsObject.cashFlowMensuel),
    cashFlowAnnuel: formatNumber(yieldsObject.cashFlowAnnuel),
    mrb: formatNumber(yieldsObject.mrb),
    mrn: formatNumber(yieldsObject.mrn),
    tgaEffectif: formatNumber(yieldsObject.tga),
  }

  const [yields, setYields] = useState(initialYields)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const mrbSecteur =
    useSelector((state: RootState) => state.statistics.mrbSecteur) || 0

  const mrnSecteur =
    useSelector((state: RootState) => state.statistics.mrnSecteur) || 0

  const tgaSecteur =
    useSelector((state: RootState) => state.statistics.tgaSecteur) || 0

  const mdf =
    useSelector((state: RootState) => state.buildingInfo.miseDeFond) || 0
 
  const remboursementBPV =
    useSelector((state: RootState) => state.creditors.remboursementBPV) || 0

  const revenuNets =
    useSelector((state: RootState) => state.incomes.revenusNets) || 0

  const revenusBruts =
    useSelector((state: RootState) => state.incomes.revenusBruts) || 0

  const hypothequeAnnuel =
    useSelector((state: RootState) => state.creditors.hypothequeAnnuel) || 0

  const hypothequeMensuel =
    useSelector((state: RootState) => state.creditors.hypothequeMensuel) || 0

  const plusValue =
    useSelector((state: RootState) => state.buildingInfo.plusValue) || 0

  const remboursEnCapital =
    useSelector((state: RootState) => state.creditors.remboursEnCapital) || 0

  const loyersBruts =
    useSelector((state: RootState) => state.incomes.loyersBruts) || 0

  const totalExpenses =
    useSelector((state: RootState) => state.totalExpenses) || 0

  useEffect(() => {
    // Calcul de ...

    const updatedYields = { ...yields }

    // CashFlow Mensuel
    updatedYields.cashFlowMensuel =
      Number(revenuNets) / 12 -
      Number(hypothequeMensuel) -
      Number(remboursementBPV)

    // CashFlow Annuel
    updatedYields.cashFlowAnnuel = yields.cashFlowMensuel * 12

    // Service de la dette
    const serviceDetteParse =
      (Number(hypothequeMensuel) + Number(remboursementBPV)) * 12
    updatedYields.serviceDette = serviceDetteParse

    // Capital emprunté
    updatedYields.capitalEmprunte = yields.serviceDette / 12

    if (Number(yields.prixOffert) !== 0 && yields.mrn !== 0) {
      updatedYields.rendement = (revenuNets / Number(yields.prixOffert)) * 100
      updatedYields.tgaEffectif = (1 / yields.mrn) * 100
    } else {
      updatedYields.rendement = 0
      updatedYields.tgaEffectif = 0 
    }

    // Rendement MDF
    updatedYields.rendementMDF =
      Number(mdf) !== 0
        ? ((Number(revenuNets) -
            Number(hypothequeAnnuel) +
            Number(plusValue) +
            Number(remboursEnCapital) * 12) /
            Number(mdf)) *
          100
        : 0

    // Taux de recouvrement de la dette
    updatedYields.tauxRecouv =
      Number(hypothequeAnnuel) !== 0 ? revenuNets / Number(hypothequeAnnuel) : 0

    // Ratio
    updatedYields.ratio =
      Number(loyersBruts) !== 0
        ? (Number(totalExpenses) / Number(loyersBruts)) * 100
        : 0

    // MRB
    updatedYields.mrb =
      Number(revenusBruts) !== 0 ? Number(yields.prixOffert) / Number(revenusBruts) : 0

    // MRN
    updatedYields.mrn =
      Number(revenuNets) !== 0 ? Number(yields.prixOffert) / Number(revenuNets) : 0

    setYields(updatedYields)

    dispatch(effectiveReturnReducer({ id: 'rendement', yields }))
    localStorage.setItem('yields', JSON.stringify(yields))
  }, [
    ...Object.values(yields),
    mdf,
    revenuNets,
    hypothequeAnnuel,
    hypothequeMensuel,
    loyersBruts,
    remboursementBPV,
    dispatch,
  ])

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let start = event.target.selectionStart
      let inputValue = event.target.value
      inputValue = inputValue.replace(/([^0-9.]+)/, '')
      inputValue = inputValue.replace(/^(0|\.)/, '')
      const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
        inputValue,
      )!
      const value = match[1] + match[2]
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)

    setYields({ ...yields, prixOffert: value })
  }

  return (
    <>
      <div key={EffectiveReturnsData[0].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[0].id}>
        {t(EffectiveReturnsData[0].name)}
        </label>
        <input
          id={EffectiveReturnsData[0].id}
          name={EffectiveReturnsData[0].id}
          type={EffectiveReturnsData[0].type}
          value={yields.prixOffert}
          className={inputClassContainer}
          onChange={HandleInput(EffectiveReturnsData[0].id)}
        />
      </div>
      <div key={EffectiveReturnsData[1].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[1].id}>
        {t(EffectiveReturnsData[1].name)}
        </label>
        <input
          id={EffectiveReturnsData[1].id}
          name={EffectiveReturnsData[1].id}
          type={EffectiveReturnsData[1].type}
          value={yields.rendement.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[2].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[2].id}>
        {t(EffectiveReturnsData[2].name)}
        </label>
        <input
          id={EffectiveReturnsData[2].id}
          name={EffectiveReturnsData[2].id}
          type={EffectiveReturnsData[2].type}
          value={yields.rendementMDF.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[3].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[3].id}>
        {t(EffectiveReturnsData[3].name)}
        </label>
        <input
          id={EffectiveReturnsData[3].id}
          name={EffectiveReturnsData[3].id}
          type={EffectiveReturnsData[3].type}
          value={yields.tauxRecouv.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[4].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[4].id}>
        {t(EffectiveReturnsData[4].name)}
        </label>
        <input
          id={EffectiveReturnsData[4].id}
          name={EffectiveReturnsData[4].id}
          type={EffectiveReturnsData[4].type}
          value={yields.serviceDette.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[5].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[5].id}>
        {t(EffectiveReturnsData[5].name)}
        </label>
        <input
          id={EffectiveReturnsData[5].id}
          name={EffectiveReturnsData[5].id}
          type={EffectiveReturnsData[5].type}
          value={yields.ratio.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[6].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[6].id}>
        {t(EffectiveReturnsData[6].name)}
        </label>
        <input
          id={EffectiveReturnsData[6].id}
          name={EffectiveReturnsData[6].id}
          type={EffectiveReturnsData[6].type}
          value={yields.capitalEmprunte.toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[7].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[7].id}>
        {t(EffectiveReturnsData[7].name)}
        </label>
        <input
          id={EffectiveReturnsData[7].id}
          name={EffectiveReturnsData[7].id}
          type={EffectiveReturnsData[7].type}
          value={yields.cashFlowMensuel.toFixed(2)}
          className={
            yields.cashFlowMensuel >= 0
              ? inputClassContainer + ' bg-green-500'
              : inputClassContainer + ' bg-red-500'
          }
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[8].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[8].id}>
        {t(EffectiveReturnsData[8].name)}
        </label>
        <input
          id={EffectiveReturnsData[8].id}
          name={EffectiveReturnsData[8].id}
          type={EffectiveReturnsData[8].type}
          value={yields.cashFlowAnnuel.toFixed(2)}
          className={
            yields.cashFlowAnnuel >= 0
              ? inputClassContainer + ' bg-green-500'
              : inputClassContainer + ' bg-red-500'
          }
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[9].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[9].id}>
        {t(EffectiveReturnsData[9].name)}
        </label>
        <input
          id={EffectiveReturnsData[9].id}
          name={EffectiveReturnsData[9].id} // Add the 'name' attribute
          type={EffectiveReturnsData[9].type}
          value={yields.mrb.toFixed(2)}
          className={
            yields.mrb <= mrbSecteur && yields.mrb >= 0
              ? inputClassContainer + ' bg-green-500'
              : inputClassContainer + ' bg-red-500'
          }
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[10].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[10].id}>
        {t(EffectiveReturnsData[10].name)}
        </label>
        <input
          id={EffectiveReturnsData[10].id}
          name={EffectiveReturnsData[10].id} // Add the 'name' attribute
          type={EffectiveReturnsData[10].type}
          value={yields.mrn.toFixed(2)}
          className={
            yields.mrn <= mrnSecteur && yields.mrn >= 0
              ? inputClassContainer + ' bg-green-500'
              : inputClassContainer + ' bg-red-500'
          }
          readOnly
        />
      </div>
      <div key={EffectiveReturnsData[11].id} className={divClassContainer}>
        <label htmlFor={EffectiveReturnsData[11].id}>
        {t(EffectiveReturnsData[11].name)}
        </label>
        <input
          id={EffectiveReturnsData[11].id}
          name={EffectiveReturnsData[11].id} // Add the 'name' attribute
          type={EffectiveReturnsData[11].type}
          value={yields.tgaEffectif.toFixed(2)}
          className={
            tgaSecteur <= yields.tgaEffectif && yields.tgaEffectif >= 0
              ? inputClassContainer + ' bg-green-500'
              : inputClassContainer + ' bg-red-500'
          }
          readOnly
        />
      </div>
    </>
  )
}

export default EffectiveReturnsContainer
