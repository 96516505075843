import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikErrors, FormikValues, FormikProps, useFormik } from 'formik'
import ConnectionButton from '../components/ConnectionButton'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { auth, sendPasswordResetEmail } from '../redux/firebase'
import Header from '../components/Header'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface MyFormValues {
  email: string
  password: string
}

const validate = (values: MyFormValues) => {
  let errors: FormikErrors<FormikValues> = {}

  if (!values.email) {
    errors.email = 'emailError'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalidEmailError'
  }

  return errors
}

const defaultInputStyles =
  'w-96 h-8 mt-1 mb-4 items-center border-solid border-2 rounded-md px-4'

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  let actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: 'http://localhost:3000/signin',
    handleCodeInApp: false,
  }

  const formik: FormikProps<MyFormValues> = useFormik<MyFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true)
      sendPasswordResetEmail(auth, values.email, actionCodeSettings)
        .then(() => {
          setTimeout(() => {
            setIsLoading(false)
            navigate('/signin')
          }, 3000)

          toast.success(t('passwordChangeSent'))
        })
        .catch((error: any) => {
          toast.error(t('passwordChangeError') )
          setIsLoading(false)
        })
    },
  })

  useEffect(() => {
    // validate;
  }, [formik.values, validate])
  return (
    <Fragment>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="relative top-20 flex flex-col flex-nowrap items-center justify-center">
        <div>
          <img
            className="mx-auto h-28 w-auto"
            src={require('../images/logo_coachingJD.png')}
            alt="logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('forgetPassword')}
          </h2>
        </div>
        <form
          className="flex-nowrap flex flex-col items-center rounded-md p-5 shadow-md h80 w-[30rem] pb-8 mt-8 space-y-6 z-10"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col justify-center">
            <label htmlFor="email">{t('enterYourEmail')}</label>
            <input
              id="email"
              name="email"
              type="email"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={
                formik.touched.email && formik.errors.email
                  ? `${defaultInputStyles} border-red-500`
                  : defaultInputStyles
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{t(formik.errors.email)}</div>
            ) : null}
          </div>
          <ConnectionButton
            title={isLoading ? t('loading') : t('changePassword')}
            disabled={formik.isSubmitting}
          />
        </form>
      </div>
    </Fragment>
  )
}

export default ForgetPassword
