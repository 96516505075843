const pmt = (
  interestRate: number,
  numberOfMonths: number,
  loanAmount: number,
  futureValue = 0,
): number => {
  // Check if the interest rate is 0% (to avoid division by zero)
  if (interestRate === 0) {
    if (numberOfMonths === 0) {
      return 0; // No time to repay, no payment needed
    } else {
      return loanAmount / numberOfMonths; // Fixed payment for each month
    }
  }

  // Calculate the monthly payment using the formula
  const presentValueInterestFactor = Math.pow(1 + interestRate, numberOfMonths);

  if (presentValueInterestFactor === 1) {
    return 0; // No interest, no payment needed
  }

  const monthlyPayment =
    (interestRate * loanAmount * (presentValueInterestFactor + futureValue)) /
    (presentValueInterestFactor - 1);

  // Round the result to two decimal places
  return monthlyPayment;
};

export default pmt;
