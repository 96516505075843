import React, { Fragment, useEffect } from 'react'
import Header from '../../components/Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMoneyBill1Wave,
  faBuildingUser,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const CalculateurAccueil = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Fragment>
      <Header />
      <div className="relative top-52">
        <h1 className="text-center uppercase text-4xl font-bold">{t('calculateurjd')}</h1>
        <div className="grid-rows-2 flex justify-center items-center m-auto w-full text-center">
          <div
            className="flex flex-col items-center justify-center m-5 p-10 bg-white shadow-lg w-1/3 h-48 cursor-pointer"
            onClick={() => navigate('/calculateur/locatif')}
          >
            <FontAwesomeIcon icon={faMoneyBill1Wave} size="3x" />
            <h3 className="mt-7">{t('calculateurLocatif')}</h3>
          </div>

          {/* <div
            className="flex flex-col items-center justify-center m-5 p-10 bg-white shadow-lg w-1/3 h-48 cursor-pointer"
            onClick={() => navigate('/calculateur/dev')}
          >
            <FontAwesomeIcon icon={faBuildingUser} size="3x" />
            <h3 className="mt-7">Calculateur développement immobilier</h3>
          </div> */}
        </div>
      </div>
    </Fragment>
  )
}

export default CalculateurAccueil
