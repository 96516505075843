// React et associés
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { expensesReducer, totalExpensesReducer } from '../redux/calculatorSlice'
import { RootState } from '../redux/calculatorSlice'

// Datas
import { ExpensesData } from '../data/CalculatorData'

// styles partagés
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../functions/HelperFunctions'
import { formatString } from '../functions/HelperFunctions'

const ExpenseContainer: React.FC = () => {
  // Les valeurs stockées dans le local storage:

  const expensesObject = JSON.parse(localStorage.getItem('expenses') || '{}')

  const initialExpenses = {
    assurance: formatString(expensesObject.assurance),
    deneigementTotal: formatNumber(expensesObject.deneigementTotal),
    pelouseTotal: formatNumber(expensesObject.pelouseTotal),
    electricite: formatString(expensesObject.electricite),
    combustible: formatString(expensesObject.combustible),
    tmunicipale: formatString(expensesObject.tmunicipale),
    tscolaire: formatString(expensesObject.tscolaire),
    autresDepenses: formatString(expensesObject.autresDepenses),
    depPorteTotal: formatNumber(expensesObject.depPorteTotal),
    fraisAdminAnnuel: formatNumber(expensesObject.fraisAdminAnnuel),
    creancesDivers: formatNumber(expensesObject.creancesDivers),
    reserve: formatNumber(expensesObject.reserve),
  }

  const [expenses, setExpenses] = useState(initialExpenses)
  const [totalExpenses, setTotalExpenses] = useState(0)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const unit =
    useSelector((state: RootState) => state.buildingInfo.nbUnite) || 0
  const tauxFraisAdmin = useSelector(
    (state: RootState) => state.statistics.tauxAdmin || 0,
  )
  const loyerBruts =
    useSelector((state: RootState) => state.incomes.loyersBruts) || 0
  const tauxCreanceDivers =
    useSelector((state: RootState) => state.statistics.tauxCreanceDivers) || 0

  const tauxReserve =
    useSelector((state: RootState) => state.statistics.tauxReserve) || 0

  const snowremoval =
    useSelector((state: RootState) => state.statistics.deneigement) || 0

  const grassremoval =
    useSelector((state: RootState) => state.statistics.pelouse) || 0

  const depensePorte =
    useSelector((state: RootState) => state.statistics.depensesCourantes) || 0

  const depExploitation: number = Object.values(expenses).reduce(
    (total: number, expense) => Number(total) + Number(expense),
    0,
  )

  useEffect(() => {
    const tauxReserveValue = Number(tauxReserve) / 100
    const tauxFraisAdminValue = Number(tauxFraisAdmin) / 100
    const tauxCreanceDiversValue = Number(tauxCreanceDivers) / 100
    const unitValue = Number(unit)

    const updatedExpenses = {
      ...expenses,
      reserve: tauxReserveValue * Number(loyerBruts),
      fraisAdminAnnuel: tauxFraisAdminValue * Number(loyerBruts),
      deneigementTotal: Number(snowremoval) * unitValue,
      pelouseTotal: Number(grassremoval) * unitValue,
      depPorteTotal: Number(depensePorte) * unitValue,
      creancesDivers: tauxCreanceDiversValue * Number(loyerBruts),
    }

    setExpenses(updatedExpenses)
    dispatch(expensesReducer({ id: 'expenses', expenses }))
    localStorage.setItem('expenses', JSON.stringify(expenses))

    if (!isNaN(depExploitation)) {
      setTotalExpenses(depExploitation)
      dispatch(
        totalExpensesReducer({
          id: 'totalexpenses',
          totalExpenses: depExploitation,
        }),
      )
      localStorage.setItem('totalExpenses', JSON.stringify(depExploitation))
    }
  }, [
    ...Object.values(expenses),
    unit,
    tauxFraisAdmin,
    loyerBruts,
    tauxCreanceDivers,
    tauxReserve,
    snowremoval,
    grassremoval,
    depensePorte,
    dispatch,
  ])

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let start = event.target.selectionStart
    let inputValue = event.target.value
    inputValue = inputValue.replace(/([^0-9.]+)/, '')
    inputValue = inputValue.replace(/^(0|\.)/, '')
    const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
      inputValue,
    )!
    const value = match[1] + match[2]
    event.target.value = Number(value).toFixed(2)
    event.target.setSelectionRange(start, start)

    switch (id) {
      case 'insurance':
        setExpenses({ ...expenses, assurance: value })
        break
      case 'electricity':
        setExpenses({ ...expenses, electricite: value })
        break
      case 'combustible':
        setExpenses({ ...expenses, combustible: value })
        break
      case 'municipaltaxe':
        setExpenses({ ...expenses, tmunicipale: value })
        break
      case 'schooltaxe':
        setExpenses({ ...expenses, tscolaire: value })
        break
      case 'autresDepenses':
        setExpenses({ ...expenses, autresDepenses: value })
        break
      default:
        break
    }
  }

  return (
    <>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[0].id}>{t(ExpensesData[0].name)}</label>
        <input
          id={ExpensesData[0].id}
          name={ExpensesData[0].id}
          type={ExpensesData[0].type}
          value={expenses.assurance}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[0].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[3].id}>{t(ExpensesData[3].name)}</label>
        <input
          id={ExpensesData[3].id}
          name={ExpensesData[3].id}
          type={ExpensesData[3].type}
          value={expenses.electricite}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[3].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[4].id}>{t(ExpensesData[4].name)}</label>
        <input
          id={ExpensesData[4].id}
          name={ExpensesData[4].id}
          type={ExpensesData[4].type}
          value={expenses.combustible}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[4].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[5].id}>{t(ExpensesData[5].name)}</label>
        <input
          id={ExpensesData[5].id}
          name={ExpensesData[5].id}
          type={ExpensesData[5].type}
          value={expenses.tmunicipale}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[5].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[6].id}>{t(ExpensesData[6].name)}</label>
        <input
          id={ExpensesData[6].id}
          name={ExpensesData[6].id}
          type={ExpensesData[6].type}
          value={expenses.tscolaire}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[6].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[11].id}>{t(ExpensesData[11].name)}</label>
        <input
          id={ExpensesData[11].id}
          name={ExpensesData[11].id}
          type={ExpensesData[11].type}
          value={Number(expenses.autresDepenses).toFixed(2)}
          className={inputClassContainer}
          onChange={HandleInput(ExpensesData[11].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={ExpensesData[12].id}>{t(ExpensesData[12].name)}</label>
        <input
          id={ExpensesData[12].id}
          name={ExpensesData[12].id}
          type={ExpensesData[12].type}
          disabled={true}
          value={Number(totalExpenses).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
    </>
  )
}

export default ExpenseContainer
