import { CreditorsData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { rapportInputClassContainer } from '../shared/classNameStyles'
import { RootState } from '../redux/calculatorSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CreancierTable = () => {
  const creditors = useSelector((state: RootState) => state.creditors) || {}
  const creditorsArray = Object.values(creditors)
  const { t } = useTranslation()

  return (
    <table className="table-auto w-full">
      <thead className="border-b">
        <tr className="bg-main-color-44">
          <th colSpan={2} className="text-white uppercase p-4 font-semibold">
          {t('bankAndLender')}
          </th>
        </tr>
      </thead>
      <tbody>
        {CreditorsData.map((creditors, index) => {
          return (
            <tr key={creditors.id} className="border-b hover:bg-gray-50">
              <td className="w-2/3 border-r-2 px-4 py-1">{t(creditors.name)}</td>
              <td
                className={
                  creditorsArray[index] < 0
                    ? rapportInputClassContainer + ' text-red-500'
                    : rapportInputClassContainer
                }
              >
                {Number(creditorsArray[index]).toFixed(2)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default CreancierTable
