import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { creditorsReducer } from '../redux/calculatorSlice'
import { CreditorsData } from '../data/CalculatorData'
import { RootState } from '../redux/calculatorSlice'
import pmt from '../functions/pmt'
import interetMensuel from '../functions/InteretMensuel'
import {
  divClassContainer,
  inputClassContainer,
} from '../shared/classNameStyles'
import { useTranslation } from 'react-i18next'

const CreditorsContainer: React.FC = () => {
  const creditorsObject = JSON.parse(localStorage.getItem('creditors') || '{}')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const prixOffert =
    useSelector((state: RootState) => state.yields.prixOffert) || '0.00'
  const mdf =
    useSelector((state: RootState) => state.buildingInfo.miseDeFond) || '0.00'

  const [creditors, setCreditors] = useState({
    montantPret: creditorsObject.montantPret,
    tauxInteret: creditorsObject.tauxInteret || '0.00',
    duree: creditorsObject.duree || 0,
    hypothequeMensuel: Number(creditorsObject.hypothequeMensuel || 0).toFixed(
      2,
    ),
    hypothequeAnnuel: Number(creditorsObject.hypothequeAnnuel || 0).toFixed(2),
    montantBPV: creditorsObject.montantbpv || '0.00',
    interetBPV: Number(creditorsObject.interetbpv || 0).toFixed(2),
    termeBPV: creditorsObject.termebpv || 0,
    remboursementBPV: Number(creditorsObject.remboursementbpv || 0).toFixed(2),
    remboursEnCapital: Number(creditorsObject.remboursEnCapital || 0).toFixed(
      2,
    ),
    montantInteret: Number(creditorsObject.montantInteret || 0).toFixed(2),
  })

  const hypothequeMensuelValue = pmt(
    Number(creditors.tauxInteret) / 100 / 12,
    creditors.duree * 12,
    Number(creditors.montantPret),
    0,
  )

  const HandleInput = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = (event.target as HTMLInputElement).valueAsNumber

    if (isNaN(value)) {
      value = 0
    }

    if (id === 'duree' || id === 'termebpv') {
      switch (id) {
        case 'duree':
          setCreditors({
            ...creditors,
            duree: value,
          })
          break
        case 'termebpv':
          setCreditors({
            ...creditors,
            termeBPV: value,
          })
        default:
          break
      }
    } else {
      let start = event.target.selectionStart
      let inputValue = event.target.value
      inputValue = inputValue.replace(/([^0-9.]+)/, '')
      inputValue = inputValue.replace(/^(0|\.)/, '')
      const match: RegExpExecArray = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(
        inputValue,
      )!
      const value = match[1] + match[2]
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)

      switch (id) {
        case 'interetannuel':
          setCreditors({
            ...creditors,
            tauxInteret: value,
          })
          break
        case 'montantbpv':
          setCreditors({
            ...creditors,
            montantBPV: value,
          })
          break
        case 'interetbpv':
          setCreditors({
            ...creditors,
            interetBPV: value,
          })
          break
      }
    }
  }

  useEffect(() => {
    setCreditors({
      ...creditors,
      montantInteret: (
        Number(creditors.montantPret) *
        Number(creditors.tauxInteret) *
        0.01
      ).toFixed(2),

      hypothequeMensuel: hypothequeMensuelValue.toFixed(2),

      hypothequeAnnuel: (Number(creditors.hypothequeMensuel) * 12).toFixed(2),

      montantPret: (
        Number(prixOffert) -
        Number(mdf) -
        Number(creditors.montantBPV)
      ).toFixed(2),

      remboursementBPV: !isNaN(creditors.termeBPV)
        ? interetMensuel(
            Number(creditors.interetBPV),
            Number(creditors.termeBPV),
            Number(creditors.montantBPV),
          ).toFixed(2)
        : '0.00',

      remboursEnCapital: !isNaN(
        Number(creditors.hypothequeMensuel) - Number(creditors.montantInteret),
      )
        ? (
            Number(creditors.hypothequeMensuel) -
            Number(creditors.montantInteret) / 12
          ).toFixed(2)
        : '0.00',
    })

    dispatch(creditorsReducer({ id: 'creditors', creditors }))
    localStorage.setItem('creditors', JSON.stringify(creditors))
  }, [...Object.values(creditors), prixOffert, mdf, dispatch])

  return (
    <>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[0].id}>{t(CreditorsData[0].name)}</label>
        <input
          id={CreditorsData[0].id}
          name={CreditorsData[0].id}
          type={CreditorsData[0].type}
          className={inputClassContainer + ' bg-gray-300'}
          value={Number(creditors.montantPret).toFixed(2)}
          readOnly
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[1].id}>{t(CreditorsData[1].name)}</label>
        <input
          id={CreditorsData[1].id}
          name={CreditorsData[1].id}
          type={CreditorsData[1].type}
          min={1}
          className={inputClassContainer}
          value={creditors.tauxInteret}
          onChange={HandleInput(CreditorsData[1].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[2].id}>{t(CreditorsData[2].name)}</label>
        <input
          id={CreditorsData[2].id}
          name={CreditorsData[2].id}
          type={CreditorsData[2].type}
          min={5}
          value={creditors.duree}
          className={inputClassContainer}
          onChange={HandleInput(CreditorsData[2].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[3].id}>{t(CreditorsData[3].name)}</label>
        <input
          id={CreditorsData[3].id}
          name={CreditorsData[3].id}
          type={CreditorsData[3].type}
          className={inputClassContainer + ' bg-gray-300'}
          value={Number(creditors.hypothequeMensuel).toFixed(2)}
          onChange={HandleInput(CreditorsData[3].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[4].id}>{t(CreditorsData[4].name)}</label>
        <input
          id={CreditorsData[4].id}
          name={CreditorsData[4].id}
          type={CreditorsData[4].type}
          value={Number(creditors.hypothequeAnnuel).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[5].id}>{t(CreditorsData[5].name)}</label>
        <input
          id={CreditorsData[5].id}
          name={CreditorsData[5].id}
          type={CreditorsData[5].type}
          value={creditors.montantBPV}
          onChange={HandleInput(CreditorsData[5].id)}
          className={inputClassContainer}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[6].id}>{t(CreditorsData[6].name)}</label>
        <input
          id={CreditorsData[6].id}
          name={CreditorsData[6].id}
          type={CreditorsData[6].type}
          value={creditors.interetBPV}
          className={inputClassContainer}
          onChange={HandleInput(CreditorsData[6].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[7].id}>{t(CreditorsData[7].name)}</label>
        <input
          id={CreditorsData[7].id}
          name={CreditorsData[7].id}
          type={CreditorsData[7].type}
          value={creditors.termeBPV}
          min={0}
          className={inputClassContainer}
          onChange={HandleInput(CreditorsData[7].id)}
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[8].id}>{t(CreditorsData[8].name)}</label>
        <input
          id={CreditorsData[8].id}
          name={CreditorsData[8].id}
          type={CreditorsData[8].type}
          value={Number(creditors.remboursementBPV).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
      <div className={divClassContainer}>
        <label htmlFor={CreditorsData[9].id}>{t(CreditorsData[9].name)}</label>
        <input
          id={CreditorsData[9].id}
          name={CreditorsData[9].id}
          type={CreditorsData[9].type}
          value={Number(creditors.remboursEnCapital).toFixed(2)}
          className={inputClassContainer + ' bg-gray-300'}
          readOnly
        />
      </div>
    </>
  )
}

export default CreditorsContainer
