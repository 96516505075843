import React from 'react'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  tokenReducer,
  isLoginReducer,
  RootState,
} from '../redux/calculatorSlice'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import LanguageSwitcher from '../functions/LanguageSwitcher'
import { useTranslation } from 'react-i18next'

const navigation: any = [
  { name: 'Calculateur', href: '/calculateur', current: true },
]

const ChangeNavigationHandler = () => {
  return !navigation.current
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector((state: RootState) => state.token)
  const isloggedIn =
    useSelector((state: RootState) => state.isloggedIn) || false
  const { t } = useTranslation()
  const logoutHandler = () => {
    dispatch(tokenReducer({ id: 'token', token: null }))
    dispatch(isLoginReducer({ id: 'isloggedIn', isloggedIn: false }))
    // localStorage.removeItem('MY_TOKEN')
    localStorage.clear()
    navigate('/signin')
  }

  return (
    <Fragment>
      <Disclosure as="nav" className="bg-main-color-44">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-main-color-70 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    {/* <img
                      className="hidden lg:block h-8 w-auto"
                      src={logo}
                      alt="Workflow"
                    /> */}
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item: any) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-white text-main-color-44'
                              : 'text-gray-300 hover:bg-main-color-70 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <LanguageSwitcher />
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      {isloggedIn && (
                        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src="https://media.istockphoto.com/photos/businessman-silhouette-as-avatar-or-default-profile-picture-picture-id476085198?b=1&k=20&m=476085198&s=612x612&w=0&h=Ov2YWXw93vRJNKFtkoFjnVzjy_22VcLLXZIcAO25As4="
                            alt=""
                          />
                        </Menu.Button>
                      )}
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item
                          onClick={() => {
                            localStorage.setItem(
                              'statistics',
                              JSON.stringify({}),
                            )
                            localStorage.setItem(
                              'buildingInfo',
                              JSON.stringify({}),
                            )
                            localStorage.setItem('incomes', JSON.stringify({}))
                            localStorage.setItem('expenses', JSON.stringify({}))
                            localStorage.setItem(
                              'creditors',
                              JSON.stringify({}),
                            )
                            localStorage.setItem('yields', JSON.stringify({}))
                            window.location.reload()
                          }}
                        >
                          {({ active }) => (
                            <a
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              {t('reset')}
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item onClick={logoutHandler}>
                          {({ active }) => (
                            <a
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              {t('disconnection')}
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item: any) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onChange={ChangeNavigationHandler}
                    className={classNames(
                      item.current
                        ? 'bg-white text-main-color-44'
                        : 'text-gray-300 hover:bg-main-color-70 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </Fragment>
  )
}

export default Header
