import { ExpensesData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { rapportInputClassContainer } from '../shared/classNameStyles'
import { RootState } from '../redux/calculatorSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DepensesTable = () => {
  const expenses = useSelector((state: RootState) => state.expenses) || {}
  const totalExpenses = useSelector((state: RootState) => state.totalExpenses) || 0
  const expensesArray = Object.values(expenses) || []
  const mergeExpenses =  [...expensesArray, totalExpenses]
  const { t } = useTranslation()

  return (
    <table className="table-auto w-full">
      <thead className="border-b">
        <tr className="bg-main-color-44">
          <th colSpan={2} className="text-white uppercase p-4 font-semibold">
          {t('expenses')}
          </th>
        </tr>
      </thead>
      <tbody>
        {ExpensesData.map((expenses, index) => {
          return (
            <tr key={expenses.id} className="border-b hover:bg-gray-50">
              <td className="w-2/3 border-r-2 px-4 py-1">{t(expenses.name)}</td>
              <td
                className={
                  Number(mergeExpenses[index]) < 0
                    ? rapportInputClassContainer + ' text-red-500'
                    : rapportInputClassContainer
                }
              >
                {mergeExpenses[index]}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default DepensesTable