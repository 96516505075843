import React, { useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../../components/Header'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/calculatorSlice'
import CaracteristiquesProjetP2 from '../../components/Dev/CaracteristiquesProjetsp2'
import RevenusBrutsAnnuel from '../../components/Dev/RevenusBrutsAnnuel'

const CalculateurDev = () => {
  const buildingInfo =
    useSelector((state: RootState) => state.buildingInfo) || {}
  const statistics = useSelector((state: RootState) => state.statistics) || {}
  const incomes = useSelector((state: RootState) => state.incomes) || {}
  const expenses = useSelector((state: RootState) => state.expenses) || {}
  const creditors = useSelector((state: RootState) => state.creditors) || {}
  const yields = useSelector((state: RootState) => state.yields) || {}

  const userEmail = localStorage.getItem('email')!


  return (
    <Fragment>
      <Header />

      <div className="flex justify-center flex-wrap h-full mx-auto md:grid-cols-[repeat(2,minmax(5rem,35rem))] sm:grid-cols-[repeat(1,minmax(5rem,35rem))]  lg:grid-cols-[repeat(2,minmax(5rem,55rem))] gap-5 content-center">
        <div className="flex flex-col py-5 bg-white shadow-lg">
          <div className="mb-10 bg-main-color-44 text-white">
            <h1 className="my-5 text-center uppercase">
              Caractéristiques du projet en pi2
            </h1>
          </div>
          <CaracteristiquesProjetP2 />
        </div>
        <div className="flex flex-col py-5 bg-white shadow-lg">
          <div className="mb-10 bg-main-color-44 text-white">
            <h1 className="my-5 text-center uppercase">
              Caractéristiques du projet en m2
            </h1>
          </div>
          <CaracteristiquesProjetP2 />
        </div>
        <div className="flex flex-col py-5 bg-white shadow-lg">
          <div className="mb-10 bg-main-color-44 text-white">
            <h1 className="my-5 text-center uppercase">
              Revenus Bruts Annuels
            </h1>
          </div>
          <RevenusBrutsAnnuel />
        </div>
      </div>
    </Fragment>
  )
}
export default CalculateurDev
