import { createSlice } from '@reduxjs/toolkit'

interface Statistics  {
  // Par porte
  tauxAdmin: string
  mrbSecteur: string
  mrnSecteur: string
  tgaSecteur: number
  tauxVacance: string
  tauxCreanceDivers: string
  tauxReserve: string
  deneigement: string
  pelouse: string
  depensesCourantes: string
}

interface BuildingInfo  {
  nomCourtier: string
  adresse: string
  nbUnite: number
  prixDemande: string
  plusValue: number
  tauxMDF: number
  miseDeFond: number
}

interface Incomes {
  loyersBruts: string
  loyersVacants: number
  loyersNets: number
  stationnement: string
  entretienMenager: string
  autresRevenus: string
  revenusBruts: number
  revenusNets: number
}

interface Expenses {
  assurance: string
  deneigementTotal: number, 
  pelouseTotal: number,
  electricite: string
  combustible: string
  tMunicipales: string
  tscolaires: string
  autresDepenses: string
  depPorteTotal: number,
  fraisAdminAnnuel: number,
  creancesDivers: number,
  reserve: number
}

interface Creditors {
  pret: number
  tauxInteret: string
  duree: number
  hypothequeMensuel: number
  hypothequeAnnuel: number
  montantBPV: string
  interetBPV: number
  termeBPV: number
  remboursementBPV: number
  remboursEnCapital: number
  montantInteret: number
}

interface Yield {
  prixOffert: string
  rendement: number
  rendementMDF: number
  tauxRecouvrement: number
  serviceDette: number
  ratio: number
  capitalEmprunte: number
  cashFlowMensuel: number
  cashFlowAnnuel: number
  mrb: number
  mrn: number
  tga: number
}

export interface RootState {
  token: string
  isloggedIn: boolean
  statistics: Statistics
  buildingInfo: BuildingInfo
  incomes: Incomes
  expenses: Expenses
  creditors: Creditors
  yields: Yield
  totalExpenses: number
}

const initialState: RootState = {
  token: '',
  isloggedIn: !!localStorage.getItem('MY_TOKEN'),
  statistics: {
    tauxAdmin: '0.00',
    mrbSecteur: '0.00',
    mrnSecteur: '0.00',
    tgaSecteur: 0,
    tauxVacance: '0.00',
    tauxCreanceDivers: '0.00',
    tauxReserve: '0.00',
    deneigement: '0.00',
    pelouse: '0.00',
    depensesCourantes: '0.00',
  },
  buildingInfo: {
    nomCourtier: 'Jean-François',
    adresse: '0.00',
    nbUnite: 0,
    prixDemande: '0.00',
    plusValue: 0,
    tauxMDF: 0,
    miseDeFond: 0,
  },
  incomes: {
    loyersBruts: '0.00',
    loyersVacants: 0,
    loyersNets: 0,
    stationnement: '0.00',
    entretienMenager: '0.00',
    autresRevenus: '',
    revenusBruts: 0,
    revenusNets: 0,
  },
  expenses: {
    assurance: '0.00',
    electricite: '0.00',
    combustible: '0.00',
    tMunicipales: '0.00',
    tscolaires: '0.00',
    autresDepenses: '0,00',
    deneigementTotal: 0,
    pelouseTotal: 0,
    depPorteTotal: 0,
    fraisAdminAnnuel: 0,
    creancesDivers: 0, 
    reserve: 0
  },

  creditors: {
    pret: 0,
    tauxInteret: '0.00',
    duree: 0,
    hypothequeMensuel: 0,
    hypothequeAnnuel: 0,
    montantBPV: '0.00',
    interetBPV: 0,
    termeBPV: 0,
    remboursementBPV: 0,
    remboursEnCapital: 0,
    montantInteret: 0,
  },

  yields: {
    prixOffert: '0.00',
    rendement: 0,
    rendementMDF: 0,
    tauxRecouvrement: 0,
    serviceDette: 0,
    ratio: 0,
    capitalEmprunte: 0,
    cashFlowMensuel: 0,
    cashFlowAnnuel: 0,
    mrb: 0,
    mrn: 0,
    tga: 0,
  },

  totalExpenses: 0
}

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    tokenReducer: (state: RootState, action) => {
      state.token = action.payload.token
    },
    isLoginReducer: (state: RootState, action) => {
      state.isloggedIn = action.payload.isloggedIn
    },
    statDuMarcheReducer: (state: RootState, action) => {
      state.statistics = action.payload.statistics
    },
    buildingInfoReducer: (state: RootState, action) => {
      state.buildingInfo = action.payload.buildingInfo
    },
    revenusReducer: (state: RootState, action) => {
      state.incomes = action.payload.incomes
    },
    expensesReducer: (state: RootState, action) => {
      state.expenses = action.payload.expenses
    },

    creditorsReducer: (state: RootState, action) => {
      state.creditors = action.payload.creditors
    },

    effectiveReturnReducer: (state: RootState, action) => {
      state.yields = action.payload.yields
    },

    totalExpensesReducer: (state: RootState, action) => {
      state.totalExpenses = action.payload.totalExpenses
    }

  },
})

export const {
  tokenReducer,
  isLoginReducer,
  revenusReducer,
  expensesReducer,
  buildingInfoReducer,
  creditorsReducer,
  statDuMarcheReducer,
  effectiveReturnReducer,
  totalExpensesReducer
} = calculatorSlice.actions

export default calculatorSlice.reducer
