import jsPDF from 'jspdf'

// Default export is a4 paper, portrait, using millimeters for units

function PrintDocument(): void {
  const report = document.querySelector('#report') as HTMLDivElement
  const width = report.offsetWidth
  const height = report.offsetHeight + 50
  const d = new Date()

  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: [height, width],
  })

  try {
    doc.html(report, {
      callback: function (doc) {
        doc.save(
          `rentabilite_${d.getFullYear()}_0${
            d.getMonth() + 1
          }_${d.getDate()}_${d.getHours()}H${d.getMinutes()}`,
        )
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export default PrintDocument
