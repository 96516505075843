import { EffectiveReturnsData } from '../data/CalculatorData'
import { useSelector } from 'react-redux'
import { rapportInputClassContainer } from '../shared/classNameStyles'
import { RootState } from '../redux/calculatorSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RapportTable = () => {
  const yields = useSelector((state: RootState) => state.yields) || {}
  const yieldArray = Object.values(yields) || []
  const { t } = useTranslation()

  return (
    <table className="table-auto w-full">
      <thead className="border-b">
        <tr className="bg-main-color-44">
          <th colSpan={2} className="text-center uppercase text-white p-4 font-semibold">
          {t('result')}
          </th>
        </tr>
      </thead>
      <tbody>
        {EffectiveReturnsData.map((yields, index) => {
          return (
            <tr key={yields.id} className="border-b hover:bg-gray-50">
              <td className="w-2/3 border-r-2 px-4 py-1">{t(yields.name)}</td>
              <td
                className={
                  yieldArray[index] < 0
                    ? rapportInputClassContainer + ' text-white' + ' bg-red-600'
                    : rapportInputClassContainer
                }
              >
                {Number(yieldArray[index]).toFixed(2)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default RapportTable