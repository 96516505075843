import React from 'react'
import { Fragment } from 'react'

import StatistiquesTable from '../tablesRapport/StatistiquesTable'
import InfosImmeubleTable from '../tablesRapport/InfosImmeubleTable'
import RevenusTable from '../tablesRapport/RevenusTable'
import DepensesTable from '../tablesRapport/DepensesTable'
import CreancierTable from '../tablesRapport/CreancierTable'
import RapportTable from '../tablesRapport/RapportTable'
import Header from './Header'
import PrintDocument from '../functions/PrintDoc'
import { useTranslation } from 'react-i18next'

const Print = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <Header />
      <div className="w-1/6 bg-main-color-44 text-center uppercase text-white block px-3 py-2 mx-auto my-2 rounded-md text-base font-medium">
        <button onClick={PrintDocument.bind(this)}>{t('export')}</button>
      </div>
      <div id="report" className="bg-white">
        <div className="text-center uppercase font-extrabold text-2xl m-5">
          <h1>{t('profitReport')}</h1>
        </div>
        <div className="overflow-x-auto border-x border-t">
          <StatistiquesTable />
          <InfosImmeubleTable />
          <RevenusTable />
          <DepensesTable />
          <CreancierTable />
          <RapportTable />
        </div>
      </div>
    </Fragment>
  )
}

export default Print
