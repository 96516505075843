import React from 'react'
import { Fragment } from 'react'
import Header from '../../components/Header'
// import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './carousel.scss' // Import your custom CSS file

import BuildingInfoContainer from '../../components/InfosImmeuble'
import IncomeContainer from '../../components/Revenus'
import ExpenseContainer from '../../components/Depenses'
import BanKAndCreditorContainer from '../../components/Creanciers'
import StatistiquesContainer from '../../components/Statistiques'
import EffectiveReturnsContainer from '../../components/Rendement'
import { useSelector } from 'react-redux'
import {
  updateUserStatDuMarche,
  updateUserBuildingInfo,
  updateUserIncomes,
  updateUserExpenses,
  updateUserCreditors,
  updateUserReturn,
  getUserDocId,
} from '../../data/usersData'
import { RootState } from '../../redux/calculatorSlice'
import { useTranslation } from 'react-i18next'

const CalculateurLocatif = () => {
  const buildingInfo =
    useSelector((state: RootState) => state.buildingInfo) || {}
  const statistics = useSelector((state: RootState) => state.statistics) || {}
  const incomes = useSelector((state: RootState) => state.incomes) || {}
  const expenses = useSelector((state: RootState) => state.expenses) || {}
  const creditors = useSelector((state: RootState) => state.creditors) || {}
  const yields = useSelector((state: RootState) => state.yields) || {}
  const { t } = useTranslation()

  const userEmail = localStorage.getItem('email')!

  // Classes
  // const carouselClassName = "h-full m-auto my-5 grid sm:grid-cols-[repeat(1,minmax(5rem,45rem))] justify-center"
  const containerClassName = 'flex flex-col py-5 bg-white shadow-lg'
  const HeaderTextClassName = 'my-5 text-center uppercase'
  const containerTitleClassName = 'mb-10 bg-main-color-44 text-white'
  const titleClassName = 'my-5 text-center uppercase'
  const saveButtonTitleClassName =
    'w-1/3 bg-main-color-44 text-center text-white block px-3 py-2 mx-auto mt-2 rounded-md text-base font-medium'

  const save = async () => {
    await getUserDocId(userEmail).then((docId) => {
      updateUserStatDuMarche(docId, statistics)
      updateUserBuildingInfo(docId, buildingInfo)
      updateUserIncomes(docId, incomes)
      updateUserExpenses(docId, expenses)
      updateUserCreditors(docId, creditors)
      updateUserReturn(docId, yields)
    })
  }

  return (
    <Fragment>
      <Header />
      <div className={saveButtonTitleClassName}>
        <button onClick={save}>
          <a href="/calculateur/locatif/print" target="_blank">
            {t('saveReport')}
          </a>
        </button>
      </div>

      <div className="h-full mx-auto my-5  grid md:grid-cols-[repeat(2,minmax(5rem,35rem))] sm:grid-cols-[repeat(1,minmax(5rem,35rem))]  grid-rows-[minmax(5rem,fitcontent(25rem))_repeat(2,minmax(2rem,fitcontent(30rem)))] gap-10 justify-center content-center">
        <div className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={titleClassName}>{t('statistics')}</h1>
          </div>
          <StatistiquesContainer />
        </div>
        <div id="renseignement" className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={HeaderTextClassName}>{t('buildingInfo')}</h1>
          </div>

          <BuildingInfoContainer />
        </div>
        <div id="revenus" className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={HeaderTextClassName}>{t('incomes')}</h1>
          </div>
          <IncomeContainer />
        </div>
        <div id="depenses" className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={HeaderTextClassName}>{t('expenses')}</h1>
          </div>
          <ExpenseContainer />
        </div>
        <div id="bank" className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={HeaderTextClassName}>{t('bankAndLender')}</h1>
          </div>
          <BanKAndCreditorContainer />
        </div>
        <div id="rapport" className={containerClassName}>
          <div className={containerTitleClassName}>
            <h1 className={HeaderTextClassName}>{t('result')}</h1>
          </div>
          <EffectiveReturnsContainer />
        </div>
      </div>
    </Fragment>
  )
}
export default CalculateurLocatif
